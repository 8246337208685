import React, { useState, useRef, useEffect } from "react";
import "../assets/styles/features.css";
import m1 from "../assets/images/Chipping5.png";
import m2 from "../assets/images/m2.svg";
import m3 from "../assets/images/m3.svg";
import m4 from "../assets/images/m3.svg";
import m5 from "../assets/images/m5.svg";
import m6 from "../assets/images/m6.svg";
import m7 from "../assets/images/m7.svg";
import m8 from "../assets/images/m8.svg";
import m9 from "../assets/images/m9.svg";
import AOS from "aos"; // Import AOS
import "aos/dist/aos.css"; // Import AOS CSS for styles

function Features() {
  const [activeIndex, setActiveIndex] = useState(0); // To track the active feature card
  const [isMobile, setIsMobile] = useState(false); // To track if the device is mobile
  const featureCardsRef = useRef([]);
  const intervalRef = useRef(null); // To store the interval reference

  // Array of feature cards
  const features = [
    {
      title: "AI Task Allocation",
      description:
        "Optimize your workflow with our AI-driven model that assigns tasks to the best-suited team member based on their performance, task completion speed, and work history.",
      icon: m1,
    },
    {
      title: "Live Working Time Tracking",
      description:
        "Monitor your team's real-time work hours to ensure efficiency and accountability.",
      icon: m2,
    },
    {
      title: "Real-Time Screens View",
      description:
        "Observe live screen activity, giving you full transparency on your team's progress.",
      icon: m3,
    },
    {
      title: "Automatic Screenshots",
      description:
        "Receive periodic screenshots to better assess task focus and compliance.",
      icon: m4,
    },
    {
      title: "Mouse Click Activity",
      description:
        "Gain insights into team engagement levels with click activity data.",
      icon: m5,
    },
    {
      title: "Internal Meeting Scheduling",
      description:
        "Effortlessly organize and coordinate internal meetings with our integrated scheduler.",
      icon: m6,
    },
    {
      title: "Leave Management",
      description:
        "Simplify leave requests and approvals with an intuitive leave management feature.",
      icon: m7,
    },
    {
      title: "Live Chat",
      description:
        "Keep communication flowing with seamless real-time chat capabilities.",
      icon: m8,
    },
    {
      title: "Scheduled Work Time Alerts",
      description:
        "Be notified when employees miss scheduled work times, ensuring work hours compliance.",
      icon: m9,
    },
  ];

  // Handle dot click to change active index
  const handleDotClick = (index) => {
    setActiveIndex(index);
    const cardContainer = featureCardsRef.current;
    // Scroll to the selected card
    cardContainer.scrollTo({
      left: cardContainer.children[index].offsetLeft,
      behavior: "smooth",
    });

    // Reset interval when user interacts with dots
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  };

  // Auto slide function for mobile only
  const autoSlide = () => {
    setActiveIndex((prevIndex) => {
      const nextIndex = (prevIndex + 1) % features.length; // Loop back to first feature
      const cardContainer = featureCardsRef.current;
      cardContainer.scrollTo({
        left: cardContainer.children[nextIndex].offsetLeft,
        behavior: "smooth",
      });
      return nextIndex;
    });
  };

  // Check if the device is mobile
  const checkMobile = () => {
    if (window.innerWidth <= 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  // Set interval for auto sliding (only for mobile)
  useEffect(() => {
    // Check if it’s a mobile device
    checkMobile();
    window.addEventListener("resize", checkMobile); // Listen to window resize to update the screen size

    // If it's mobile, set the auto-slide interval
    if (isMobile) {
      intervalRef.current = setInterval(autoSlide, 3000); // Change slide every 3 seconds
    }

    // Initialize AOS for scroll animations
    AOS.init({
      duration: 1200,
      once: true,
    });

    // Cleanup interval and resize listener on component unmount or screen resize
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      window.removeEventListener("resize", checkMobile);
    };
  }, [isMobile]); // Dependency on isMobile to restart interval on mobile device change

  return (
    <div id="features" className="features-container">
      <div className="features-header" data-aos="fade-up">
        <h2>Our Features</h2>
        <p>
          Our AI-powered tool offers a comprehensive suite of features to make
          remote <br /> work not just manageable but exceptionally efficient:
        </p>
      </div>
      <div className="features-grid" ref={featureCardsRef}>
        {features.map((feature, index) => (
          <div
            key={index}
            className="feature-card"
            style={{
              opacity: index === activeIndex ? 1 : 1, // Highlight active card
              transition: "opacity 0.3s ease", // Smooth transition for opacity
            }}
            data-aos="fade-up"
            data-aos-delay={`${index * 100}`} // Delay based on index for staggered animations
          >
            <div className="feature-content">
              <img
                src={feature.icon}
                alt={feature.title}
                className="feature-image"
                data-aos="zoom-in"
              />
              <div className="feature-text" data-aos="fade-left">
                <h3>{feature.title}</h3>
                <p>{feature.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Dots for mobile */}
      <div className="feature-dots">
        {features.map((_, index) => (
          <div
            key={index}
            className={`dot ${index === activeIndex ? "active" : ""}`}
            onClick={() => handleDotClick(index)}
          ></div>
        ))}
      </div>
    </div>
  );
}

export default Features;
