import React, { useRef, useState, useEffect } from "react";
import { FaRegPlayCircle } from "react-icons/fa";
import { FaRegCirclePause } from "react-icons/fa6";
import "../assets/styles/news.css"; // Create a separate CSS file for styling
import video from "../assets/images/intro.mp4"; // Replace with your actual video path
import thumbnail from "../assets/images/employee.png"; // Path to your thumbnail image
import AOS from "aos"; // Import AOS
import "aos/dist/aos.css"; // Import AOS CSS for styles

// News Section Component
const NewsSection = () => {
  const [isPlaying, setIsPlaying] = useState(false); // State for managing play/pause
  const videoRef = useRef(null);

  // Function to toggle play/pause
  const togglePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    // Initialize AOS
    AOS.init({
      duration: 1200,
      once: true,
    });
  }, []);

  return (
    <section id="news" className="news-section">
      <div className="news-container" data-aos="fade-up">
        <h3 data-aos="fade-up" data-aos-delay="100">News</h3>
        <h2 data-aos="fade-up" data-aos-delay="200">
          "Discover Revolutionizing Remote Work Management With AI"
        </h2>
        <p className="news-description" data-aos="fade-up" data-aos-delay="300">
          Feel Free To Replace Syncturma With Your Organization's Name For A Personalized Touch.<br />
          If You Need Any Adjustments Or Have A Specific Theme In Mind, Let Me Know!
        </p>
        <div className="news-media" data-aos="fade-up" data-aos-delay="400">
          {/* Video with a ref to control play/pause */}
          <video
            ref={videoRef}
            className="news-video"
            src={video}
            type="video/mp4"
            preload="auto"
            poster={thumbnail} // Adding the thumbnail image here
            muted={false}
          />
          <div className="play-button" onClick={togglePlayPause}>
            {isPlaying ? (
              <FaRegCirclePause size={50} color="#5E1F87" />
            ) : (
              <FaRegPlayCircle size={50} color="#5E1F87" />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewsSection;
