import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css"; 
import { Link } from 'react-scroll';
import '../assets/styles/hero.css';
import robot from '../assets/images/newFrame.png';
import macbookImage from '../assets/images/MacbookPro.png'; 
import ScrollIndicator from './scroll';
import kk from '../assets/images/kk.png'; 
import star from '../assets/images/Container.svg';
import hair from '../assets/images/hairsvg.svg';

const HeroSection = () => {
  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: true,
    });
  }, []);

  return (
    <>
      {/* Hero Section */}
      <section className="hero-section">
        <div className="hero-container" id="home" data-aos="fade-up">
          <p>Revolutionize</p>
          <h1>
            Remote{" "}
            <span style={{ position: "relative" }}>
              <img
                src={star}
                alt="Decorative SVG above e"
                className="decorative-star"
             
              />
            </span>
            <br />
            Workforce <br /> Management <br />
            with AI
          </h1>
          <div data-aos="fade-up" data-aos-delay="200">
            <Link
              to="about"
              smooth={true}
              duration={800}
              className="hero-button"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              Request Demo
            </Link>

            {/* Hair Image with absolute positioning */}
            <img
              src={hair}
              alt="Decorative Hair SVG"
              className="hero-hair-svg"
              style={{
                position: "absolute",
                
                top: "-150%", // Adjust as necessary
                left: "65%", // Adjust as necessary
                zIndex: 1,  // Ensure it's above other content
              }}
            />
          </div>
        </div>

        {/* Hero Background */}
        <div
          className="hero-background"
          style={{ backgroundImage: `url(${robot})` }}
          data-aos="fade-in"
        ></div>

        {/* Scroll Indicator */}
        <ScrollIndicator />
      </section>

      <div
        className="hero-robot-icon"
        data-aos="zoom-in"
        data-aos-delay="400"
      >
        <img src={kk} alt="Robot Icon" />
      </div>

      {/* About Section */}
      <section id="about" className="about-section">
        <div
          className="about-container"
          data-aos="fade-up"
          data-aos-delay="500"
        >
          <h3>About Us</h3>
          <h2>
            Revolutionize Remote Workforce <br /> Management with AI
          </h2>
          <div className="about-content">
            <img
              src={macbookImage}
              alt="Macbook displaying the platform"
              data-aos="zoom-in"
              data-aos-delay="600"
            />
            <div
              className="about-text"
              data-aos="fade-left"
              data-aos-delay="700"
            >
              <p>
              At Syncturma, we are dedicated to transforming the way remote teams work using the latest advancements in AI technology. Our mission is to empower HR leaders, employers, and teams across the globe by offering innovative solutions that optimize remote workforce management, drive productivity, and foster seamless collaboration.
              </p>
              <br />
              <p style={{ marginBottom: "80px" }}>
              Our intelligent solutions are designed to adapt to the unique demands of every organization, automating routine tasks, enhancing efficiency, and delivering actionable insights. We believe technology should enhance your operations, allowing your team to focus on what truly matters—achieving exceptional results. With a commitment to continuous innovation, we provide cutting-edge tools to help businesses thrive in a dynamic and evolving digital world.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HeroSection;
